













































import useMisc from "@/use/misc";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
      default: "",
    },
  },

  setup(_, { root, emit }) {
    const { getSurveyAnswerType } = useMisc({ root });

    const model = reactive({
      nfcTag: null,
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
    });

    const closeDialog = () => {
      emit("close-dialog");
    };

    const print = () => {
      const node: any = document.querySelector(".mail-content");

      const mywindow: any = window.open("", "PRINT", "height=800,width=1000");

      mywindow.document.write(node.innerHTML);

      mywindow.document.close();
      mywindow.focus();

      setTimeout(mywindow.print(), 1000);
      mywindow.close();
    };

    const createFile = (item: any) => {
      const buffer = Buffer.from(item.content);
      const ext = item.filename.split(".").pop();
      let fileType;

      switch (ext) {
        case "pdf":
          fileType = "application/pdf";
          break;
        case "jpg":
          fileType = "image/jpeg";
          break;
        case "jpeg":
          fileType = "image/jpeg";
          break;
        case "doc":
          fileType = "application/msword";
          break;
        case "docx":
          fileType =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        case "png":
          fileType = "image/png";
          break;
        case "zip":
          fileType = "application/zip";
          break;
        case "rar":
          fileType = "application/vnd.rar";
          break;
        default:
          fileType = "text/plain";
      }

      const blob = new Blob([buffer], { type: fileType });

      const fileURL = window.URL.createObjectURL(blob);
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${item.filename}`);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    };

    return {
      model,
      state,
      closeDialog,
      print,
      createFile,
      getSurveyAnswerType,
    };
  },
});
